import React from 'react'
import { Link, graphql } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/seo'

class TheMathsBehindBitcoin extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allMarkdownRemark.edges

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title="The Maths Behind Bitcoin series"
          keywords={[`blog`, `math`, `javascript`, `react`, `react native`]}
        />
        <h1>The Maths Behind Bitcoin series</h1>

        <h2>Articles</h2>
        <ol>
          {posts.map((post) => (
            <li key={post.node.frontmatter.title}>
              <Link to={post.node.fields.slug}>
                {post.node.frontmatter.title}
              </Link>
              , {post.node.frontmatter.date}
            </li>
          ))}
          <li>Groups and Elliptic Curves, TBA</li>
          <li>... and more, TBA</li>
        </ol>

        <h2>Talks</h2>
        <ul>
          <li>
            <a
              href="https://www.meetup.com/fr-FR/Life-Learning-Programming-Prague/events/259892147/"
              rel="noopener"
            >
              Learning By Doing Meetup
            </a>
            , Prague, Czech Republic, March 25, 2019 <br />
            <em>Finite Fields and ECDSA</em> [
            <a
              href="https://sylv.icu/25032019-finite-fields-ECDSA-prague"
              rel="noopener"
            >
              slides
            </a>
            ]
          </li>
          <li>
            <a
              href="https://www.meetup.com/fr-FR/Life-Learning-Programming-Prague/events/259291581/"
              rel="noopener"
            >
              Learning By Doing Meetup
            </a>
            , Prague, Czech Republic, March 04, 2019 <br />
            <em>Group Theory and Elliptic Curves</em> [
            <a
              href="https://sylv.icu/20190304-group-theory-elliptic-curves-prague"
              rel="noopener"
            >
              slides
            </a>
            ]
          </li>
          <li>
            <a
              href="https://www.meetup.com/Blockchain-Brno/events/258730912/"
              rel="noopener"
            >
              Blockchain Meetup
            </a>
            , Brno, Czech Republic, February 19, 2019 <br />
            <em>The Maths Behind Bitcoin</em> [
            <a
              href="https://sylv.icu/20190219-maths-behind-bitcoin-brno"
              rel="noopener"
            >
              slides
            </a>
            ,{' '}
            <a
              href="https://www.youtube.com/watch?v=w_sCH54Ehjs"
              rel="noopener"
            >
              video
            </a>
            ]
          </li>
          <li>
            <a
              href="https://www.meetup.com/Life-Learning-Programming-Prague/events/258872130/"
              rel="noopener"
            >
              Learning By Doing Meetup
            </a>
            , Prague, Czech Republic, February 18, 2019 <br />
            <em>Geometry 101</em> [
            <a
              href="https://sylv.icu/20190218-gemetry-101-prague"
              rel="noopener"
            >
              slides
            </a>
            ]
          </li>
          <li>
            <a
              href="https://www.meetup.com/Life-Learning-Programming-Prague/events/254146193/"
              rel="noopener"
            >
              Learning By Doing Meetup
            </a>
            , Prague, Czech Republic, September 24, 2018
          </li>
          <li>
            <a
              href="https://www.meetup.com/Life-Learning-Programming-Prague/events/fcwrzpyxkbnc/"
              rel="noopener"
            >
              Learning By Doing Meetup
            </a>
            , Prague, Czech Republic, July 30, 2018
          </li>
        </ul>
      </Layout>
    )
  }
}

export default TheMathsBehindBitcoin

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: ASC }
      filter: { fields: { slug: { regex: "/^/the-maths-behind-bitcoin/.*/" } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
          }
        }
      }
    }
  }
`
